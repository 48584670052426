<template>
	<div class="outer-wrapper">
		<div class="auth login">
			
			<div class="inner-wrapper">

				<div class="heading-section">
					<h3>Sign In</h3>
					<div class="caption">
						<span>New User? </span><router-link :to="{ name: 'Register', query: { redirect: redirect }}">Create an account</router-link>
					</div>
				</div>

				<form @submit.prevent="handleSubmit">
					<div class="form-group auth">
						<label for="email">Email address</label>
						<input type="text" v-model="loginUser.email" name="email" class="form-control" :class="{ 'is-invalid': submitted && errors.has('email') }" />
						<div v-if="submitted && errors.has('email')" class="invalid-feedback">{{ errors.get('email')[0] }}</div>
					</div>
					
					<Loader :loading="loading"/>

					<div class="form-group btn-container">
						<button class="button">Continue</button>
					</div>
					<div class="line-container">
						<div class="line">
							<span class="caption ln-or">Or</span>
						</div>
					</div>
				</form>
				
				<div class="btn-container btns-bottom">
					<div class="my-signin2" id="my-signin2">
						<button class="button btn-google caption" @click="googlePrompt()"><img src="@/assets/auth/google-icon.png" alt="google-login" class="google-img" /><span class="google-text" id="google-text">Continue with Google</span></button>
					</div>
					<!-- <button class="button btn-facebook" @click="facebookOnSignIn()" ><img src="@/assets/auth/facebook-icon.png" alt="facebook-login" class="fb-img" /><span class="fb-text">Continue with Facebook</span></button> -->
				</div>
			</div>
		</div>
	</div>
</template>


<script>

import { mapState, mapActions } from 'vuex';
import router from '@/router';
import account from '@/helpers/account.js';
import Loader from '@/components/Loader'

export default {
    data () {
        return {
            email: '',
            password: '',
			submitted: true,
			errors: new Map(),
			loading: false,

			redirect: this.$route.query.redirect != 'login' ? this.$route.query.redirect : null,
        }
	},
	components: {
		Loader
	},
    computed: {
		...mapState('account', ['status', 'loginUser']),
		...mapState({
				alert: state => state.alert,
			}),
    },
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'home',
			},
			right: {
				type: 'none',
			},
		});
	},
    methods: {
        ...mapActions('account', ['login', 'googleLogin', 'logout', 'facebookLogin']),
      	...mapActions({
			alertError: "alert/error",
			alertSuccess: "alert/success",
		}),
		// attachSignin(element) {
		// 	var auth2 = gapi.auth2.getAuthInstance();
		// 	auth2.attachClickHandler(element, {},
		// 		(googleUser) => {
		// 			document.getElementById('google-text').innerText = "Signed in.";
					
		// 			this.googleOnSignIn(googleUser);
		// 		}, function(error) {
		// 			console.log(JSON.stringify(error, undefined, 2));
		// 		});
		// },
		googleLogout() {
			// let gapi = window.gapi;
			var auth2 = gapi.auth2.getAuthInstance();
			if(auth2) {
				auth2.signOut().then(function () {
					console.log('User signed out of Google.');
				});
			}
			
		},
		async googleOnSignIn (user) {
			document.getElementById('google-text').innerText = "Signed in.";

			// const profile = user.getBasicProfile();
			// let token = user.getAuthResponse().id_token;
			let token = user.credential;
			this.loading = true;
			// this.googleLogin({ email: profile.Nt, googleToken: token })
			this.googleLogin({ googleToken: token })
				.then((success) => {
					this.loading = false;
					if(success) {
						this.alertSuccess("Logged in successfully")
						router.push(this.redirect || '/');
					};
				})
				.catch(error => {
					this.$rollbar.error(error);
					this.loading = false;
					this.alertError(error);
				});
		},
		facebookOnSignIn() {
			FB.login((response) => {
				// Send API call to backend to sign the user up / log the user in
				console.log("User logged in via Facebook");
				this.loading = true;
				this.facebookLogin({ facebookToken: "" })
					.then((success) => {
						this.loading = false;
						if(success) {
							this.alertSuccess("Logged in successfully")
							router.push(this.redirect || '/');
						};
					})
					.catch(error => {
						this.$rollbar.error(error);
						this.loading = false;
						this.alertError(error);
					});
			}, {scope: 'public_profile, email, name'}); // Set the required details from the user
		},
		facebookLogout() {
			FB.logout(function(response) {
				console.log("User logged out of facebook")
			});
		},
		checkForm() {
			this.errors = new Map();
			let emailErrors = [];
			if(!this.loginUser.email || this.loginUser.email == null) {
				emailErrors.push('Email is required');
			}
			if(!account.validEmail(this.loginUser.email)) {
				emailErrors.push('Must be a valid email');
			}
			if(emailErrors.length > 0) {
				this.errors.set('email', emailErrors);
			}
			if (!this.errors.size > 0) {
				return true;
			}
			return false;
		},
		handleSubmit (e) {
            if(this.checkForm()) {
				router.push({ name: "Login Password", query: { redirect: this.redirect }})
			}
			e.preventDefault();
        },

		googlePrompt() {
			google.accounts.id.prompt((notification) => {
				if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
					this.alertError("There is currently an issue using Google Login")
				}

			});
		},
		test() {
			router.push({ name: "Login", query: { redirect: 'login' }})
		}
	},
	mounted () {
		// reset login status
		this.logout();
		console.log("User logged out locally")

		// gapi.load('auth2', () => {
		// 	// Logout of Google
		// 	this.googleLogout();
			
		// 	// Retrieve the singleton for the GoogleAuth library and set up the client.
		// 	var auth2 = gapi.auth2.init({
		// 		client_id: '352368229046-2t2lcijnavvjusufsoelgscothphepmp.apps.googleusercontent.com',
		// 		cookiepolicy: 'single_host_origin',
		// 	});
		// 	this.attachSignin(document.getElementById('my-signin2'));
		// });

		google.accounts.id.initialize({
			client_id: '352368229046-2t2lcijnavvjusufsoelgscothphepmp.apps.googleusercontent.com',
			// client_id: '454293056308-6ovbs8n9uri5ne1ciplods9mbr3cpatp.apps.googleusercontent.com',
			callback: this.googleOnSignIn,
		});


		// FB.getLoginStatus((response) => {
		// 	if(response.status === 'connected') {				
		// 		// Logout (login page when connected acts as a force signout)
		// 		this.facebookLogout();
		// 	} else {

		// 	}
		// });
	},
};
</script>


<style scoped>
	.login .form-group {
		padding-bottom: 54px;
	}
	.login form {
		margin: auto;
	}
	.login .btn-container {
		text-align: center;
		display: block;
	}
	.btns-bottom {
		margin-bottom: 30px;
	}
	.login .line {
		position: relative;
		height: 1px;
		width: 100%;
		background: #C4C4C4;	
		max-width: 374px;
		margin: 0 auto;
	}

	.login .ln-or {
		position: absolute;
		top: -14px;
		left: 50%;
		transform: translateX(-50%);
		background-color: white;
		width: 45px;
		text-align: center;
	}
	.login .btn-google {
		background: #fff;
		color: #000;
		font-weight: 400;
		border: 1px solid #E5E5E5;
		margin-top: 41px;
	}
	.login .google-img {
		width: 17px;
		height: 18px;
		position: absolute;
		margin-right: 11px;
		padding-top: 4px;
	}
	.login .google-text {
		padding-left: 27px;
	}
	.login .btn-facebook, .btn-apple {
		margin-top: 13px;
	}
	.login .btn-facebook {
		background: #3B5998;
		font-weight: 400;
	}
	.login .fb-img {
		width: 17px;;
		height: 17px;
		position: absolute;
		margin-right: 11px;
		padding-top: 4px;
	}
	.login .fb-text {
		padding-left: 27px;
	}
	.login .btn-apple {
		background: #000000;
		font-weight: 400;
	}
</style>